<template>
  <div class="firstPage" v-if="ispc">
    <!-- <headers></headers> -->
    <div class="content">
      <div class="left_text"> 
        <div class="w-22">
          Hyper-Converged Intelligent Monitoring Platform
        </div>
        <div class="w-29 tc-normal mb-20">
          Including video surveillance, AI, Internet of things, big data analysis, etc.
        </div>
        <el-button @click="addfree">
          <span>
            Free Trial
            <img src="@/assets/img/logo/btnlogo.png">
          </span>
        </el-button>
        <div class="contectus">
          <span>CONTECT US</span>
          <img v-if="fcshow" src="@/assets/img/logo/facebook.png" @mouseover="hoverfc" @mouseout="hoverfc">
          <img v-else src="@/assets/img/logo/huifacebook.png" @mouseover="hoverfc" @mouseout="hoverfc">

          <img v-if="inshow" style="cursor:pointer;" src="@/assets/img/logo/in.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">
          <img v-else style="cursor:pointer;" src="@/assets/img/logo/huiin.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">

          <img v-if="wxshow" src="@/assets/img/logo/wechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
          <img v-else src="@/assets/img/logo/huiwechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
        </div>
      </div>
      <div class="right_img">
        <img src="@/assets/img/logo/bigpic1.png">
      </div>
      <div class="center_write">
        Focus on enterprise digital workflow reengineering
      </div>
    </div>
    <div class="monitor">
      <div class="first">
        <div class="logoimg">
          <img class="cameralogo" src="@/assets/img/logo/camera.png">
          <div class="video_left">
            <div>Monitoring service</div>
            <p>Video AI analysis finds potential safety hazards and alerts in time.</p>
          </div>
        </div>
        <img style="height:100%;float:left;" src="@/assets/img/logo/video.png">
      </div>
    </div>
    <div class="monitor intell">
      <div class="first">
        <img style="height:100%;float:left;" src="@/assets/img/logo/cityimg.png">
        <div class="logoimg logoimg1">
          <img class="intellogo" src="@/assets/img/logo/intell.png">
          <div class="video_left">
            <div>Intelligent algorithm service</div>
            <p>Customized development enables rapid training of iterative models for small samples in special scenes.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="monitor intell inspection">
      <div class="first">
        <div class="logoimg">
          <img class="cameralogo" src="@/assets/img/logo/inspectlogo.png">
          <div class="video_left">
            <div>Inspection service</div>
            <p>Push the on-site video and working conditions information in real time to realize the visualization of safety conditions,
              online patrol inspection and digitalization of information management.</p>
          </div>
        </div>
        <img style="height:100%;float:left;" src="@/assets/img/logo/inspect.png">
      </div>
    </div>
    <div class="tabqh">
      <el-tabs v-model="activeName">
        <el-tab-pane label="Technical Introduction" name="first">
         <div class="imgbox">
          <div class="left_img">
            <img src="@/assets/img/logo/algo.png">
          </div>
          <div class="right_img">
            <img src="@/assets/img/logo/phone.png">
            <img src="@/assets/img/logo/shexiang.png">
          </div>
         </div>
        </el-tab-pane>
        <el-tab-pane label="Business Solution" name="second">
          <div class="imgbox">
            <div class="two_leftimg">
              <img src="@/assets/img/logo/computer.png">
            </div>
            <div class="two_righttext">
              <div>Hyper-converged intelligent monitoring platform & mobile app</div>
              <p>It is a highly integrated automatic monitoring system, which does not need on-site deployment. </p>
              <p>It serves construction site, electricity, transportation and other scenarios, and supports multi-user, cross industry and cross device seamless connection.</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Applicable Industries" name="third">
          <div class="imgbox">
            <div class="left_img">
              <img src="@/assets/img/logo/three1.png">
            </div>
            <div class="right_img">
              <img src="@/assets/img/logo/three2.png">
              <img src="@/assets/img/logo/three3.png">
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="waitingforbox">
      <div class="waitingfor">
        <div class="waitingfortext">
          <div>What are you waiting for?</div>
          <p>Let's try the Intelligent Monitoring Platform for free</p>
        </div>
        <el-button @click="addfree">
          <span>
            Free Trial
            <img src="@/assets/img/logo/btnlogo.png">
          </span>
        </el-button>
      </div>
    </div>
    <img class="bkclass" src="@/assets/img/logo/dbbak.png">
    <footers></footers>
  </div>
  <div class="firstPage" v-else>
    <!-- <headers></headers> -->
    <div class="content">
      <div class="left_text"> 
        <div class="w-22 w-221">
          Hyper-Converged Intelligent Monitoring Platform
        </div>
        <div class="w-29 tc-normal mb-20 w-291">
          Including video surveillance, AI, Internet of things, big data analysis, etc.
        </div>
        <!-- <el-button @click="addfree">
          <span>
            Free Trial
            <img src="@/assets/img/logo/btnlogo.png">
          </span>
        </el-button> -->
        <div class="contectus contectus1">
          <span>CONTECT US</span>
          <img v-if="fcshow" src="@/assets/img/logo/facebook.png" @mouseover="hoverfc" @mouseout="hoverfc">
          <img v-else src="@/assets/img/logo/huifacebook.png" @mouseover="hoverfc" @mouseout="hoverfc">

          <img v-if="inshow" style="cursor:pointer;" src="@/assets/img/logo/in.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">
          <img v-else style="cursor:pointer;" src="@/assets/img/logo/huiin.png" @click="jumpins" @mouseover="hoverin" @mouseout="hoverin">

          <img v-if="wxshow" src="@/assets/img/logo/wechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
          <img v-else src="@/assets/img/logo/huiwechat.png" @mouseover="hoverwx" @mouseout="hoverwx">
        </div>
      </div>
      <div class="right_img">
        <img src="@/assets/img/logo/bigpic1.png">
      </div>
      <div class="center_write center_write1">
        Focus on enterprise digital workflow reengineering
      </div>
    </div>
    <div class="monitor monitor1">
      <div class="first">
        <div class="logoimg">
          <img class="cameralogo" src="@/assets/img/logo/camera.png">
          <div class="video_left">
            <div>Monitoring service</div>
            <p>Video AI analysis finds potential safety hazards and alerts in time.</p>
          </div>
        </div>
        <img style="height:100%;float:left;" src="@/assets/img/logo/video.png">
      </div>
    </div>
    <div class="monitor intell monitor1">
      <div class="first">
        <img style="height:100%;float:left;" src="@/assets/img/logo/cityimg.png">
        <div class="logoimg logoimg1">
          <img class="intellogo" src="@/assets/img/logo/intell.png">
          <div class="video_left">
            <div>Intelligent algorithm service</div>
            <p>Customized development enables rapid training of iterative models for small samples in special scenes.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="monitor intell inspection monitor1">
      <div class="first">
        <div class="logoimg">
          <img class="cameralogo" src="@/assets/img/logo/inspectlogo.png">
          <div class="video_left">
            <div>Inspection service</div>
            <p>Push the on-site video and working conditions information in real time to realize the visualization of safety conditions,
              online patrol inspection and digitalization of information management.</p>
          </div>
        </div>
        <img style="height:100%;float:left;" src="@/assets/img/logo/inspect.png">
      </div>
    </div>
    <div class="tabqh tabqh1">
      <el-tabs v-model="activeName">
        <el-tab-pane label="Technical Introduction" name="first">
         <div class="imgbox">
          <div class="left_img">
            <img src="@/assets/img/logo/algo.png">
          </div>
          <div class="right_img">
            <img src="@/assets/img/logo/phone.png">
            <img src="@/assets/img/logo/shexiang.png">
          </div>
         </div>
        </el-tab-pane>
        <el-tab-pane label="Business Solution" name="second">
          <div class="imgbox">
            <div class="two_leftimg">
              <img src="@/assets/img/logo/computer.png">
            </div>
            <div class="two_righttext">
              <div>Hyper-converged intelligent monitoring platform & mobile app</div>
              <p>It is a highly integrated automatic monitoring system, which does not need on-site deployment. </p>
              <p>It serves construction site, electricity, transportation and other scenarios, and supports multi-user, cross industry and cross device seamless connection.</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Applicable Industries" name="third">
          <div class="imgbox">
            <div class="left_img">
              <img src="@/assets/img/logo/three1.png">
            </div>
            <div class="right_img">
              <img src="@/assets/img/logo/three2.png">
              <img src="@/assets/img/logo/three3.png">
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="waitingforbox waitingforbox1">
      <div class="waitingfor">
        <div class="waitingfortext">
          <div>What are you waiting for?</div>
          <p>Let's try the Intelligent Monitoring Platform for free</p>
        </div>
        <el-button @click="addfree">
          <span>
            Free Trial
            <img src="@/assets/img/logo/btnlogo.png">
          </span>
        </el-button>
      </div>
    </div>
    <img class="bkclass1" src="@/assets/img/logo/dbbak.png">
    <footers></footers>
  </div>
</template>

<script>
import router from "@/router/index.js";
import headers from "@/components/header.vue"
import footers from "@/components/footers.vue"
export default {
  name: "mainPage",
  data() {
    return {
      ispc:true,
      activeIndex: '1',
      activeName: 'first',
      fcshow:true,
      inshow:true,
      wxshow:true,
    };
  },
  components:{
    footers,
    headers
  },
  mounted(){
    let w = document.documentElement.clientWidth || document.body.clientWidth;
    if(w <= 900){
      this.ispc = false
    }else{
      this.ispc = true
    }
  },
  methods: {
    backToFirstPage() {
      router.push("/firstPage");
    },
    addfree(){
      this.$router.push('/FreeTrial')
    },
    jumpins(){
      window.open('https://www.linkedin.com/company/chasing-ai-pte-ltd/')
    },
    hoverfc(){
      this.fcshow = !this.fcshow
    },
    hoverin(){
      this.inshow = !this.inshow
    },
    hoverwx(){
      this.wxshow = !this.wxshow
    }
  },
};
</script>


<style scoped lang="scss">
.firstPage {
  width: 100%;
  // height: 100vh;
  color: dimgrey;
  position: relative;
}

.bkclass{
  width: 100%;
  position: absolute;
  bottom:20px;
  z-index: -9999;
}

.bkclass1{
  width: 100%;
  position: absolute;
  bottom:200px;
  z-index: -9999;
}

a {
  color: dimgrey;
}
a:hover {
  color: black;
  cursor: pointer;
}

.navIcon:hover {
  cursor: pointer;
}

.header {
  width: 100%;
  height: 100px;
  padding: 0 117px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #0D8AF2;
  display: flex;
  justify-content: flex-start;
  /* box-shadow: 0px 4px 8px rgba(0, 33, 64, 0.04); */
  /* backdrop-filter: blur(20px); */
}
.headerBox {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.leftBorder {
  border-left: 2px solid rgb(177, 177, 177);
}

.tabs{
  width:100%;
  height:60px !important;
  margin-left:50px;
}

.tabs .el-menu{
  background:#0D8AF2;
  width:65%;
  border-bottom:0;
  float: left;
}

.tabs .el-menu .el-menu-item{
  width:24%;
  border-bottom:0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color:#fff;
}

.tabs .el-menu .el-menu-item:hover{
  background:#0D8AF2;
  color:#dedede;
}

.tabs .el-menu .is-active{
  background:#0D8AF2;
}

.header_right{
  width:31%;
  height:60px;
  background:#0D8AF2;
  float: left;
  // border-left:1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header_right p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color:#fff;
}

.header_right .el-button,
.left_text .el-button{
  width:170px;
  height:40px;
  padding:0;
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  background: #FFA000;
  color:#fff;
  border-radius: 10px;
  border:0;
}

.left_text .el-button:hover{
  background: #FF8A00;
  // color:#dedede;
}

.content{
  width:100%;
  height:1500px;
  // position:absolute;
  // top:100px;
  background: url("../assets/img/logo/headerback.png") no-repeat;
  background-size: 100% 100%;
  .left_text{
    width:50%;
    position: absolute;
    top: 260px;
    left: 6.5%;
    .el-button{
      margin-bottom:12%;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        img{
          margin-left:10px;
          width: 18px;
          height:20px;
        }
      }
    }
    .contectus{
      width:450px;
      height:40px;
      background:#0D8AF2;
      display: flex;
      align-items: center;
      span{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        color: #DEDEDE;
      }
      img{
        width:28px;
        height:28px;
        margin-left:20px;
      }
    }

    .contectus1{
      width:580px;
      height:80px;
      background:#0D8AF2;
      display: flex;
      align-items: center;
      span{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        color: #DEDEDE;
      }
      img{
        width:45px;
        height:45px;
        margin-left:30px;
      }
    }
  }
  .right_img{
    width:50%;
    position: absolute;
    top:330px;
    right:6.5%;
    img{
      width:100%;
    }
  }
  .center_write{
    width:30%;
    height:204px;
    position:absolute;
    top:1150px;
    left:35%;
    text-align:center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height:75px;
    color: rgba(0, 0, 0, 0.87);
  }
  .center_write1{
    font-size: 50px;
    top:1100px;
    width:35%;
  }
}

.monitor1{
  height:500px !important;
  .first{
    width:80% !important;
    .video_left{
      width:90%;
      margin-top:12% !important;
      div{
        font-family: 'Montserrat-bold';
        font-style: normal;
        font-weight: 900;
        font-size: 43px !important;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 40px;
      }
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 38px !important;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  .cameralogo{
    width:78px !important;
    height:86px !important;
  }
}

.tabqh1{
  padding-top:80px;
  .el-tabs{
    width:80% !important;
  }
  /deep/.el-tabs__item{
    font-size: 38px !important;
  }
}

.monitor{
  height:420px;
  width:100%;
  display: flex;
  justify-content: center;
  margin-top:80px;
  .first{
    width:67%;
    height:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
    .intellogo{
      width:70px;
      height:81px;
      // position:absolute;
      // top:-20px;
      // left:52%;
    }
    .logoimg{
      width:48%;
      height: 100%;
      .cameralogo{
        width:70px;
        height:81px;
      }
    }

    // .logoimg1{
    //   margin-left:20px;
    // }

    .video_left{
      width:90%;
      margin-top:15%;
      div{
        font-family: 'Montserrat-bold';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 40px;
      }
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

.tabqh{
  display: flex;
  justify-content: center;
  margin-bottom:300px;
  .el-tabs{
    width:63%;
  }
  /deep/.el-tabs__item{
    height:70px;
    padding-right:60px; 
    padding-left:60px;
    font-family: 'Montserrat-bold';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
  }

  /deep/.el-tabs__header{
    margin-bottom:70px;
  }

  /deep/.el-tabs__nav-wrap::after{
    background-color: transparent;
  }
}

.imgbox{
  width:100%;
  height:500px;
  .left_img{
    width:55%;
    height:100%;
    float:left;
    margin-right:15px;
    img{
      width:100%;
      height:100%;
    }
  }
  .two_leftimg{
    width:65%;
    height:100%;
    float:left;
    margin-right:15px;
    img{
      width:100%;
      height:100%;
    }
  }
  .right_img{
    width:43%;
    height:100%;
    float:left;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    img{
      width:100%;
      height:245px;
    }
  }
  .two_righttext{
    div{
      font-family: 'Montserrat-bold';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      color: #000000;
      margin-bottom:80px;
      padding-top:20px;
    }
    p{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }
  }
}

.waitingforbox{
  width:100%;
  height:220px;
  display: flex;
  justify-content: center;
  margin-bottom:120px;
  .waitingfor{
    width:75%;
    height:100%;
    background: #0D8AF2;
    border-radius: 40px;
    display: flex;
    align-items: center;
    position:relative;
    .waitingfortext{
      position:absolute;
      left:11%;
      color:#fff;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        margin-bottom:30px;
      }
      p{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 500;
        font-size:18px;
      }
    }
    .el-button{
      position:absolute;
      left:70%;
      width:270px;
      height:65px;
      padding:0;
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
      background: #FFA000;
      color:#fff;
      border-radius: 10px;
      border:0;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        img{
          margin-left:20px;
          width: 25px;
          height:23px;
        }
      }
    }
    .el-button:hover{
      background:#FF8A00;
    }
  }
}

.waitingforbox1{
  margin-top:0;
  height:350px;
  .waitingfor{
    width: 80% !important;
    .waitingfortext{
      position:absolute;
      left:7% !important;
      color:#fff;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 50px !important;
        margin-bottom:30px;
      }
      p{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 500;
        font-size:30px !important;
      }
    }
    .el-button{
      position:absolute;
      left:70%;
      width:350px !important;
      height:90px !important;
      padding:0;
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
      background: #FFA000;
      color:#fff;
      border-radius: 10px;
      border:0;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size:38px;
      }
      img{
        margin-left:20px;
        width: 25px;
        height:23px;
      }
    }
  }
}

.intell{
  margin-top:200px;
}

.inspection{
  margin-bottom:140px;
  height:600px !important;
  .first{
    width:63%;
  }
  // .video_left{
  //   width:40% !important;
  // }
}


.w-22{
  width:60%;
  color:#fff;
  line-height: 60px;
  margin-bottom:38px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
}

.w-221{
  width: 70%; 
  font-size: 65px;
}

.w-291{
  font-size: 55px !important;
  line-height:55px !important;
  margin-bottom:70px !important;
}

.w-29{
  width:60%;
  color:#dedede;
  margin-bottom:60px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height:45px;
}


</style>